<template>
  <b-container fluid>
    <b-row v-if="billDetail">
      <div class="col-sm-12">
        <div class="iq-card position-relative inner-page-bg bg-primary" style="height: 150px;">
          <div class="inner-page-title">
            <h3 class="text-white">
              {{planDetail.subs_plan_name}}
            </h3>
            <p class="text-white">
              <span v-for="(subsModule, indexM) in getSubsModules(planDetail)" :key="indexM+'B'">{{ subsModule }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <b-col lg="12">
        <iq-card>
          <div>
            <table
              border="0"
              cellspacing="0"
              cellpadding="0"
              width="100%"
              bgcolor="white"
              style="
          font-size: 12px;
          font-style: normal;
          font-variant-caps: normal;
          font-weight: normal;
          letter-spacing: normal;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          white-space: normal;
          word-spacing: 0px;
          background-color: white;
          text-decoration: none;
          font-family: Helvetica, Arial, sans-serif;
        ">
              <tbody>
                <tr>
                  <td>
                    <table
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                      width="1">
                      <tbody>
                        <tr>
                          <td>
                            <div style="height: 5px; font-size: 5px; line-height: 5px">
                              &nbsp;<span>&nbsp;</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table
                      cellspacing="0"
                      cellpadding="0"
                      border="0"
                      align="center"
                      width="100%"
                      style="
                  table-layout: fixed;
                  font-family: Helvetica, Arial, sans-serif;
                ">
                      <tbody>
                        <tr>
                          <td align="center">
                            <table
                              border="0"
                              cellspacing="0"
                              cellpadding="0"
                              width="550"
                              style="
                          font-family: Helvetica, Arial, sans-serif;
                          min-width: 290px;
                        ">
                              <tbody>
                                <tr>
                                  <td align="left">
                                    <table
                                      border="0"
                                      cellspacing="0"
                                      cellpadding="0"
                                      width="100%">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <table
                                              width="1"
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="0"
                                              style="
                                          font-family: Helvetica, Arial, sans-serif;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div
                                                      style="
                                                  height: 8px;
                                                  font-size: 8px;
                                                  line-height: 8px;
                                                ">
                                                      &nbsp;<span>&nbsp;</span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <table
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="0"
                                              width="100%"
                                              style="
                                          font-family: Helvetica, Arial, sans-serif;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    valign="middle"
                                                    width="95"
                                                    height="38"
                                                    id="m_1632458173640752469base-header-logo"
                                                    style="
                                                background-size: 95px;
                                                background-image: url('https://web.gide.ai//img/gide_logo.1b5642f8.png');
                                                width: 95px !important;
                                                height: 38px !important;
                                                background-repeat: no-repeat
                                                  no-repeat;
                                              ">
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table
                                      border="0"
                                      cellspacing="0"
                                      cellpadding="0"
                                      width="100%"
                                      style="
                                  font-size: 12px;
                                  font-family: Helvetica, Arial, sans-serif;
                                ">
                                      <tbody>
                                        <tr>
                                          <td
                                            align="left"
                                            style="
                                        border-bottom-width: 1px;
                                        border-bottom-style: solid;
                                        border-bottom-color: rgb(187, 187, 187);
                                      ">
                                            <table
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="0"
                                              width="100%"
                                              style="
                                          font-family: Helvetica, Arial, sans-serif;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td style="font-size: 14px">
                                                    Order ID:<br />Pay 123
                                                  </td>
                                                  <td align="right">
                                                    <table
                                                      width="1"
                                                      border="0"
                                                      cellspacing="0"
                                                      cellpadding="1"
                                                      style="
                                                  font-family: Helvetica, Arial,
                                                    sans-serif;
                                                ">
                                                      <tbody>
                                                        <tr>
                                                          <td>
                                                            <div
                                                              style="
                                                          height: 5px;
                                                          font-size: 5px;
                                                          line-height: 5px;
                                                        ">
                                                              &nbsp;<span>&nbsp;</span>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                    <span style="font-size: 12px">Miniventure lab <br />
                                                      sd for cdc sdfa
                                                      <br />India, 333042<br />TAX
                                                      INVOICE<br />78718<br/></span>
                                                    <table
                                                      width="1"
                                                      border="0"
                                                      cellspacing="0"
                                                      cellpadding="1"
                                                      style="
                                                  font-family: Helvetica, Arial,
                                                    sans-serif;
                                                ">
                                                      <tbody>
                                                        <tr>
                                                          <td>
                                                            <div
                                                              style="
                                                          height: 5px;
                                                          font-size: 5px;
                                                          line-height: 5px;
                                                        ">
                                                              &nbsp;<span>&nbsp;</span>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left">
                                            <table
                                              width="1"
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="1"
                                              style="
                                          font-family: Helvetica, Arial, sans-serif;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div
                                                      style="
                                                  height: 30px;
                                                  font-size: 30px;
                                                  line-height: 30px;
                                                ">
                                                      &nbsp;<span>&nbsp;</span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="0"
                                              width="100%"
                                              style="
                                          font-family: Helvetica, Arial, sans-serif;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    valign="top"
                                                    width="50%">
                                                    <table
                                                      cellpadding="0"
                                                      cellspacing="0"
                                                      border="0"
                                                      style="
                                                  font-family: Helvetica, Arial,
                                                    sans-serif;
                                                ">
                                                      <tbody>
                                                        <tr>
                                                          <td
                                                            valign="top"
                                                            style="padding-right: 10px">
                                                            <b style="font-size: 12px">Billed To:</b>
                                                          </td>
                                                          <td valign="top">
                                                            <table
                                                              border="0"
                                                              cellspacing="0"
                                                              cellpadding="0"
                                                              width="100%"
                                                              style="
                                                          font-family: Helvetica,
                                                            Arial, sans-serif;
                                                        ">
                                                              <tbody>
                                                                <tr>
                                                                  <td
                                                                    style="
                                                                font-size: 12px;
                                                              ">
                                                                    Shubham
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="
                                                                font-size: 12px;
                                                              "></td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="
                                                                font-size: 12px;
                                                              ">
                                                                    302036
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="
                                                                font-size: 12px;
                                                              ">
                                                                    India
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                  <td
                                                    align="right"
                                                    valign="top"
                                                    width="50%">
                                                    <table
                                                      cellpadding="0"
                                                      cellspacing="0"
                                                      border="0"
                                                      style="
                                                  font-family: Helvetica, Arial,
                                                    sans-serif;
                                                ">
                                                      <tbody>
                                                        <tr>
                                                          <td
                                                            align="left"
                                                            style="
                                                        padding: 2px 10px 2px 0px;
                                                      ">
                                                            <b style="font-size: 12px">Date:</b>
                                                          </td>
                                                          <td
                                                            align="right"
                                                            width="30%"
                                                            style="font-size: 12px">
                                                            21/02/2022
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td
                                                            align="left"
                                                            style="
                                                        padding: 2px 10px 2px 0px;
                                                      ">
                                                            <b style="font-size: 12px">Order Total:</b>
                                                          </td>
                                                          <td
                                                            align="right"
                                                            width="30%"
                                                            style="font-size: 12px">
                                                            $ 95
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td
                                                            align="left"
                                                            style="
                                                        padding: 2px 10px 2px 0px;
                                                      ">
                                                            <b style="font-size: 12px">Payment Method:</b>
                                                          </td>
                                                          <td
                                                            align="right"
                                                            width="30%"
                                                            style="font-size: 12px">
                                                            Razorpay
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td
                                                            align="left"
                                                            style="
                                                        padding: 2px 10px 2px 0px;
                                                      ">
                                                            <b style="font-size: 12px">Receipt #:</b>
                                                          </td>
                                                          <td
                                                            align="right"
                                                            width="30%"
                                                            style="font-size: 12px">
                                                            1595768076
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left">
                                            <table
                                              width="1"
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="1"
                                              style="
                                          font-family: Helvetica, Arial, sans-serif;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div
                                                      style="
                                                  height: 25px;
                                                  font-size: 25px;
                                                  line-height: 25px;
                                                ">
                                                      &nbsp;<span>&nbsp;</span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table
                                              cellpadding="5"
                                              cellspacing="1"
                                              border="0"
                                              width="100%"
                                              style="
                                          font-family: Helvetica, Arial, sans-serif;
                                          border-collapse: collapse;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td
                                                    width="11%"
                                                    align="center"
                                                    style="
                                                font-size: 12px;
                                                border-top-width: 1px;
                                                border-top-style: solid;
                                                border-top-color: rgb(
                                                  187,
                                                  187,
                                                  187
                                                );
                                                border-bottom-width: 1px;
                                                border-bottom-style: solid;
                                                border-bottom-color: rgb(
                                                  187,
                                                  187,
                                                  187
                                                );
                                              "
                                                    class="text-color-black">
                                                    Item
                                                  </td>
                                                  <td
                                                    width="51%"
                                                    align="left"
                                                    style="
                                                font-size: 12px;
                                                border: 1px solid rgb(187, 187, 187);
                                              "
                                                    class="text-color-black">
                                                    Plan
                                                  </td>
                                                  <td
                                                    width="10%"
                                                    align="center"
                                                    style="
                                                font-size: 12px;
                                                border-top-width: 1px;
                                                border-top-style: solid;
                                                border-top-color: rgb(
                                                  187,
                                                  187,
                                                  187
                                                );
                                                border-bottom-width: 1px;
                                                border-bottom-style: solid;
                                                border-bottom-color: rgb(
                                                  187,
                                                  187,
                                                  187
                                                );
                                              "
                                                    class="text-color-black">
                                                    Qty
                                                  </td>
                                                  <td
                                                    width="14%"
                                                    align="center"
                                                    style="
                                                font-size: 12px;
                                                border: 1px solid rgb(187, 187, 187);
                                              "
                                                    class="text-color-black">
                                                    Rate
                                                  </td>
                                                  <td
                                                    width="14%"
                                                    align="center"
                                                    style="
                                                font-size: 12px;
                                                border-top-width: 1px;
                                                border-top-style: solid;
                                                border-top-color: rgb(
                                                  187,
                                                  187,
                                                  187
                                                );
                                                border-bottom-width: 1px;
                                                border-bottom-style: solid;
                                                border-bottom-color: rgb(
                                                  187,
                                                  187,
                                                  187
                                                );
                                              "
                                                    class="text-color-black">
                                                    Price
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    align="center"
                                                    valign="top"
                                                    style="font-size: 12px">
                                                    1
                                                  </td>
                                                  <td align="left" valign="top">
                                                    <span style="font-size: 12px">Gide Plus</span>
                                                  </td>
                                                  <td
                                                    align="center"
                                                    valign="top"
                                                    style="font-size: 12px"></td>
                                                  <td
                                                    align="right"
                                                    valign="top"
                                                    style="
                                                white-space: nowrap;
                                                font-size: 12px;
                                              ">
                                              &nbsp;
                                                  </td>
                                                  <td
                                                    align="right"
                                                    valign="top"
                                                    style="
                                                white-space: nowrap;
                                                font-size: 12px;
                                              ">
                                                    $ 95
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table
                                              width="1"
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="1"
                                              style="
                                          font-family: Helvetica, Arial, sans-serif;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div
                                                      style="
                                                  height: 10px;
                                                  font-size: 10px;
                                                  line-height: 10px;
                                                ">
                                                      &nbsp;<span>&nbsp;</span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left">
                                            <table
                                              cellpadding="4"
                                              cellspacing="0"
                                              border="0"
                                              width="100%"
                                              style="
                                          font-family: Helvetica, Arial, sans-serif;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    width="61%"
                                                    rowspan="6">
                                              &nbsp;
                                                  </td>
                                                  <td
                                                    align="left"
                                                    width="27%"
                                                    valign="top"
                                                    class="text-color-black"
                                                    style="font-size: 12px">
                                                    Total Purchases:
                                                  </td>
                                                  <td
                                                    align="right"
                                                    width="12%"
                                                    valign="top"
                                                    style="font-size: 12px"
                                                    class="text-color-black">
                                                    $ 95
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    width="27%"
                                                    valign="top"
                                                    style="
                                                border-bottom-width: 1px;
                                                border-bottom-style: solid;
                                                border-bottom-color: rgb(
                                                  187,
                                                  187,
                                                  187
                                                );
                                                font-size: 12px;
                                              "
                                                    class="text-color-black">
                                                    GST :
                                                  </td>
                                                  <td
                                                    align="right"
                                                    width="12%"
                                                    valign="top"
                                                    style="
                                                border-bottom-width: 1px;
                                                border-bottom-style: solid;
                                                border-bottom-color: rgb(
                                                  187,
                                                  187,
                                                  187
                                                );
                                                font-size: 12px;
                                              ">
                                                    $ 0
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    width="27%"
                                                    valign="top"
                                                    style="font-size: 12px"
                                                    class="text-color-black">
                                                    Order Total:
                                                  </td>
                                                  <td
                                                    align="right"
                                                    width="12%"
                                                    valign="top"
                                                    style="font-size: 12px">
                                                    $ 95
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    width="27%"
                                                    valign="top"
                                                    style="
                                                border-bottom-width: 1px;
                                                border-bottom-style: solid;
                                                border-bottom-color: rgb(
                                                  187,
                                                  187,
                                                  187
                                                );
                                                font-size: 12px;
                                              "
                                                    class="text-color-black">
                                                    Payment:
                                                  </td>
                                                  <td
                                                    align="right"
                                                    width="12%"
                                                    valign="top"
                                                    style="
                                                border-bottom-width: 1px;
                                                border-bottom-style: solid;
                                                border-bottom-color: rgb(
                                                  187,
                                                  187,
                                                  187
                                                );
                                                font-size: 12px;
                                              ">
                                                    <span style="color: rgb(153, 0, 0)">-$ 95</span>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    width="27%"
                                                    valign="top"
                                                    style="font-size: 12px"
                                                    class="text-color-black">
                                                    <b>Balance:</b>
                                                  </td>
                                                  <td
                                                    align="right"
                                                    width="12%"
                                                    valign="top"
                                                    style="
                                                white-space: nowrap;
                                                font-size: 12px;
                                              ">
                                                    <b>₹0.00</b>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table
                                              width="1"
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="1"
                                              style="
                                          font-family: Helvetica, Arial, sans-serif;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div
                                                      style="
                                                  height: 15px;
                                                  font-size: 15px;
                                                  line-height: 15px;
                                                ">
                                                      &nbsp;<span>&nbsp;</span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left">
                                            <table
                                              border="0"
                                              cellspacing="0"
                                              cellpadding="0"
                                              width="100%"
                                              style="
                                          padding: 0px;
                                          margin: 0px;
                                          font-family: Helvetica, Arial, sans-serif;
                                          border-collapse: collapse;
                                        ">
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    width="50"
                                                    valign="top"
                                                    style="font-size: 12px"
                                                    class="text-color-black">
                                                    Notes:
                                                  </td>
                                                  <td
                                                    align="left"
                                                    valign="top"
                                                    style="font-size: 12px">
                                                    <sup
                                                      style="
                                                  font-size: 9px;
                                                  vertical-align: top;
                                                ">1</sup><span>&nbsp;</span>Charges may
                                                    continue until the job post is closed
                                                    or paused.
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table
                      width="1"
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                      style="font-family: Helvetica, Arial, sans-serif">
                      <tbody>
                        <tr>
                          <td>
                            <div style="height: 20px; font-size: 20px; line-height: 20px">
                              &nbsp;<span>&nbsp;</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <img
              alt=""
              role="presentation"
              src="https://web.gide.ai//img/gide_logo.1b5642f8.png"
              style="
          font-family: Helvetica;
          font-size: 12px;
          font-style: normal;
          font-variant-caps: normal;
          font-weight: normal;
          letter-spacing: normal;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          white-space: normal;
          word-spacing: 0px;
          background-color: white;
          text-decoration: none;
          width: 1px;
          height: 1px;
        "
              class="CToWUd"/>
            <div class="yj6qo">
            </div>
            <div class="adL">
              <span
                style="
            font-family: Helvetica;
            font-size: 12px;
            font-style: normal;
            font-variant-caps: normal;
            font-weight: normal;
            letter-spacing: normal;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            word-spacing: 0px;
            background-color: white;
            text-decoration: none;
            float: none;
            display: inline !important;
          "></span>
            </div>
          </div>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style lang="scss" scoped>
  .moduleList {
    text-transform: capitalize;
  }
</style>

<script>
import { socialvue } from "../config/pluginInit"
import { PayTransactionss } from "../FackApi/api/PayTransactions"
import { UserBillDetails } from "../FackApi/api/UserBillDetails"

export default {
  name: "Invoice",
  data () {
    return {
      title: "Invoice",
      invoice: {
        name: "Hello, Nik Jones",
        summary: "Order Summary",
        detail: "Order Detail",
        description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        order: [
          {
            item: 1,
            plan: "",
            // orderStatus: '',
            transactionId: "",
            // billingAddress: '',
            qty: "",
            rate: "",
            total: ""
            // billingDate: ''
          }
        ],
        note: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
      },
      planDetail: null,
      billDetail: null,
      billingDate: null
    }
  },
  async mounted () {
    socialvue.index()
    this.planDetail = await this.$store.getters["Paygw/invoicePlanState"]
    await this.UserBillDetails_view()

    this.billingDate = new Date(this.planDetail.created_on).toLocaleDateString()
    this.invoice.order[0].transactionId = this.planDetail.pay_tid
    /*
    this.invoice.order[0].billingAddress = `${this.billDetail.bill_address} <br> ${this.billDetail.bill_city}, ${this.billDetail.bill_state} ${this.billDetail.bill_zip_code}
                                    <br> ${this.billDetail.bill_email ? 'Email:' + this.billDetail.bill_email : ''} <br>
                                    ${this.billDetail.bill_phone ? 'Phone:' + this.billDetail.bill_phone : ''}` */
    this.invoice.order[0].total = this.planDetail.currency_symbol + this.planDetail.pay_amount
    this.invoice.order[0].plan = this.planDetail.subs_plan_name
  },
  methods: {
    /**
     * getSubsModules
     */
    getSubsModules (subsPlan) {
      let subsModules = []
      try {
        subsModules = subsPlan.subs_plan_modules.split(",")
      }
      catch (err) {
        console.log("Exception occurred at getSubsModules() and Exception:", err.message)
      }
      return subsModules
    },
    /**
    * getPlanWithBillDetail
    */
    async getPayTransaction () {
      const resp = await PayTransactionss.payTransactionsView(this, this.payTransactionsId)
      if (!resp.resp_status) {
        return
      }
      this.planDetail = resp.resp_data.data
    },
    /**
     * UserBillDetails_view
     */
    async UserBillDetails_view () {
      const billDetailResp = await UserBillDetails.UserBillDetailsView(this, this.planDetail.pay_user_bill_id)
      if (!billDetailResp.resp_status) {
        return
      }
      this.billDetail = billDetailResp.resp_data.data
    }
  }
}
</script>
